'use client';

import React, { useContext } from 'react';
import { buttonVariants } from '../ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { cn } from '@/lib/utils';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Link from 'next/link';
import { Skeleton } from '../ui/skeleton';
import { LoginIcon, LogoutIcon, RegisterIcon, UserIcon } from '../icons';
import { ThemeToggle } from '../theme/theme-toggle';
import { AuthContext } from '@/lib/context/auth-context';
import { motion } from 'framer-motion';

export const ProfileAvatarInner = ({  
  src,
  username,
  className,
}: {
  src: string;
  username: string;
  className?: string;
}) => {
  return (<Avatar className={cn(
    'h-10 w-10 align-middle',
    className,
  )}>
    <AvatarImage src={src} alt={username} loading='eager' />
    <AvatarFallback>{username.split(/[\s#-_@]/).map((e) => e.charAt(0) ?? '')}</AvatarFallback>
  </Avatar>);
};

export const ProfileAvatar = ({
  src,
  username,
  className,
}: {
  src: string;
  username: string;
  className?: string;
}) => {
  return (<motion.div
    initial={{ rotate: 45, scale: 1.25, opacity: 0 }}
    animate={{ rotate: 0, scale: 1, opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <ProfileAvatarInner src={src} username={username} className={className} />
  </motion.div>
  );
};

export const ProfileAvatarSkeleton = ({ className }: { className?: string }) => {
  return (
    <Skeleton className={cn('h-10 w-10 rounded-full', className)} />
  );
};

export const ThemeSwitcher = ({ className }: { className?: string }) => {
  return (
    <div className={cn('flex w-full justify-evenly', className)}>
      <ThemeToggle className='w-full border-none shadow-none h-[3rem]' iconClassName='size-6' />
    </div>
  );
};

export const AuthenticatedProfileMenu = ({
  showThemeSwitcher = false,
}: {
  showThemeSwitcher?: boolean;
}) => {
  return (<>
    <DropdownMenuLabel className='p-2'>
      My Account
    </DropdownMenuLabel>
    <DropdownMenuSeparator />
    <DropdownMenuItem className='p-0'>
      <Link href='/profile' className='flex h-full w-full p-2'>
        Profile
      </Link>
    </DropdownMenuItem>
    <DropdownMenuItem className='p-0'>
      <Link href='/profile/orders' className='flex h-full w-full p-2'>
        Orders
      </Link>
    </DropdownMenuItem>
    <DropdownMenuItem disabled className='p-0'>
      <Link href='/profile/gift-cards' className='flex h-full w-full p-2'>
        Gift Center
      </Link>
    </DropdownMenuItem>
    <DropdownMenuItem disabled className='p-0'>
      <Link href='/profile/subscriptions' className='flex h-full w-full p-2'>
        Subscriptions
      </Link>
    </DropdownMenuItem>

    <DropdownMenuSeparator />
    <DropdownMenuItem className='p-0'>
      <Link prefetch={false} href={`/api/auth/logout?return_to=${encodeURIComponent(location.origin)}`} className='flex h-full w-full p-2'>
        <LogoutIcon className='h-5 w-5 mr-2' /> Log Out
      </Link>
    </DropdownMenuItem>

    {showThemeSwitcher && <>
      <DropdownMenuSeparator />
      <ThemeSwitcher />
    </>}
  </>);
};

export const UnauthenticatedProfileMenu = ({
  showThemeSwitcher = false,
}: {
  showThemeSwitcher?: boolean;
}) => {
  return (<>
    <DropdownMenuItem className='p-0'>
      <Link prefetch={false} href={`/api/auth/discord?return_to=${encodeURIComponent(location.href)}`} className='flex h-full w-full p-2'>
        <LoginIcon className='h-5 w-5 mr-2' /> Log In
      </Link>
    </DropdownMenuItem>
    <DropdownMenuItem className='p-0'>
      <Link prefetch={false} href={`/api/auth/discord?return_to=${encodeURIComponent(location.href)}`} className='flex h-full w-full p-2'>
        <RegisterIcon className='h-5 w-5 mr-2' /> Sign Up
      </Link>
    </DropdownMenuItem>
    {showThemeSwitcher && <>
      <DropdownMenuSeparator />  
      <ThemeSwitcher />
    </>}
  </>);
};

const ProfileButton = ({
  className,
  showThemeSwitcher = false,
}: {
  className?: string;
  showThemeSwitcher?: boolean;
}) => {
  const { data, error, loading } = useContext(AuthContext);

  if (loading) return (<ProfileAvatarSkeleton className={className} />);
  if (error && !error.startsWith('Unauthenticated')) {
    // [ERROR/REPORTING]
    console.error('Error encountered while fetching user data:', error);
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn(
        'mgsm-profile-button',
        'h-10 w-10 align-middle',
        !data ? (buttonVariants({
          variant: 'outline',
        }) + ' p-0') : '',
        data ? 'cursor-pointer' : 'flex items-center justify-center',
        'bg-transparent rounded-full',
        className,
      )}>
        {data && data.discord_profile?.image_url
          ? <ProfileAvatar
            src={data.discord_profile.image_url}
            username={data.discord_profile.username ?? 'User'}
          />
          : <UserIcon className={cn(
            'h-10 w-10',
            className,
          )} />
        }
      </DropdownMenuTrigger>
      <DropdownMenuContent className='mgsm-profile-button-content p-0 gap-0 space-y-0'>
        {
          data
            ? <AuthenticatedProfileMenu showThemeSwitcher={showThemeSwitcher} />
            : <UnauthenticatedProfileMenu showThemeSwitcher={showThemeSwitcher} />
        }
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ProfileButton;
