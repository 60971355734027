'use client';

import useSWR from 'swr';
import {
  APIOrderResponse,
  APIPopulatedOrderResponse,
  APIUserMeResponse,
  APIPublicServer,
  APIServerStatusResponse,
} from '@repo/api-types';

export type APIResponseBase<T = unknown> = {
  rid: string;
  ok: boolean;
  data: T;
  message?: string;
  error?: string;
  body?: Record<string, unknown>;
}

export type APIErrorResponse = Omit<APIResponseBase, 'data'> & {
  ok: false;
  error: string;
  message: string;
  code: number;
  body: Record<string, unknown> | null;
}

export type APIDataResponse<T> = Omit<APIResponseBase<T>, 'message' | 'error' | 'body'> & {
  ok: true;
  status?: string;
}

export type APIResponse<T = unknown> = APIErrorResponse | APIDataResponse<T>;

export const isAPIErrorResponse = (response: APIResponse | undefined): response is APIErrorResponse => {
  return typeof response !== 'undefined' && response.ok === false;
};

export const isAPIDataResponse = <T>(response: APIResponse<T> | undefined): response is APIDataResponse<T> => {
  return typeof response !== 'undefined' && response.ok === true;
};

export const fetcher = (url: string, ...args: unknown[]) => {
  return fetch(url, {
    ...args,
    credentials: 'include',
  })
    .then((res) => res.json());
};
 
export const useUser = () => {
  const { data, mutate } = useSWR<APIResponse<APIUserMeResponse>>('/api/users/@me', fetcher, {
    revalidateOnFocus: false,
  });
  const isError = isAPIErrorResponse(data);
  const isSuccessful = isAPIDataResponse(data);
  const isLoading = !isSuccessful && !isError;
  return {
    mutate,
    loading: isLoading,
    data: isSuccessful ? data.data : null,
    error: isError ? {
      message: data.message,
      error: data.error,
      body: data.body,
    } : null,
  };
};

export const useOrderBySession = (session: string) => {
  const { data, mutate } = useSWR<APIResponse<APIOrderResponse>>(`/api/store/orders/@me/session/${session}`, fetcher);
  const isError = isAPIErrorResponse(data);
  const isSuccessful = isAPIDataResponse(data);
  const isLoading = !isSuccessful && !isError;

  return {
    mutate,
    loading: isLoading,
    data: isSuccessful ? data.data : null,
    error: isError ? {
      message: data.message,
      error: data.error,
      body: data.body,
    } : null,
  };
};

export const useUserOrders = () => {
  const { data, mutate } = useSWR<APIResponse<APIPopulatedOrderResponse[]>>('/api/store/orders/@me', fetcher);
  const isError = isAPIErrorResponse(data);
  const isSuccessful = isAPIDataResponse(data);
  const isLoading = !isSuccessful && !isError;

  return {
    mutate,
    loading: isLoading,
    data: isSuccessful ? data.data : null,
    error: isError ? {
      message: data.message,
      error: data.error,
      body: data.body,
    } : null,
  };
};

export const useGameServers = () => {
  const { data, mutate } = useSWR<APIResponse<APIPublicServer[]>>('/api/servers', fetcher);
  const isError = isAPIErrorResponse(data);
  const isSuccessful = isAPIDataResponse(data);
  const isLoading = !isSuccessful && !isError;

  return {
    mutate,
    loading: isLoading,
    data: isSuccessful ? data.data : null,
    error: isError ? {
      message: data.message,
      error: data.error,
      body: data.body,
    } : null,
  };
};

export const useServerStatus = (serverId: string) => {
  const { data, mutate } = useSWR<APIResponse<APIServerStatusResponse>>(`/api/game/${serverId}/status`, fetcher);
  const isError = isAPIErrorResponse(data);
  const isSuccessful = isAPIDataResponse(data);
  const isLoading = !isSuccessful && !isError;

  return {
    mutate,
    loading: isLoading,
    data: isSuccessful ? data.data : null,
    error: isError ? {
      message: data.message,
      error: data.error,
      body: data.body,
    } : null,
  };
};
