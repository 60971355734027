'use client';

import * as React from 'react';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export function ThemeToggle({ className, iconClassName }: { className?: string; iconClassName?: string; }) {
  const { setTheme, theme } = useTheme();
  return (
    <Button variant="outline" className={cn('mgsm-them-toggle', className ?? '')} size="icon" onClick={(e) => {
      e.preventDefault();
      setTheme(theme === 'dark' ? 'light' : 'dark');
    }}>
      <Sun className={cn(
        'h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0',
        'block dark:hidden',
        iconClassName,
      )} />
      <Moon className={cn(
        'h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100',
        'hidden dark:block',
        iconClassName,
      )}/>
      <span className="sr-only">Toggle Theme</span>
    </Button>
  );
}
